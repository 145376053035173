/* You can add global styles to this file, and also import other style files */
@import "~jsoneditor/dist/jsoneditor.min.css";
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; padding: 0px 50px 0px 50px;background-color: #efefef; }
.input-full-width {
    width: 100%;
}
.green-snackbar{
    background: rgb(147, 241, 155) !important;
    color: black;
}
.red-snackbar{
    background: rgb(236, 114, 114) !important;
}
